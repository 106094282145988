<!--  -->
<template>
  <div>
    <div class="help-index" v-show="isindex">
      <div class="container">
        <ul class="help-cloumn">
          <div style="clear: both">
            <li class="issue-type-item margin-left-0">
              <h4
                :style="{ background: 'no-repeat 6px 6px  url(' + img1 + ')' }"
              >
                注册与购买
              </h4>
              <p @click="isshow(1)" v-for="(item, index) in list" :key="index">
                <a>{{ item }}</a>
              </p>
            </li>
            <li class="issue-type-item">
              <h4
                :style="{ background: 'no-repeat 6px 6px  url(' + img2 + ')' }"
              >
                产品问题
              </h4>
              <p @click="isshow(2)" v-for="(item, index) in list2" :key="index">
                <a>{{ item }}</a>
              </p>
            </li>
            <li class="issue-type-item">
              <h4
                :style="{ background: 'no-repeat 6px 6px  url(' + img3 + ')' }"
              >
                使用规则
              </h4>
              <p @click="isshow(3)" v-for="(item, index) in list3" :key="index">
                <a>{{ item }}</a>
              </p>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <div class="main-area border-box" v-show="!isindex">
      <div class="auto clearfix">
        <div class="help-menu">
          <dt class="help-list-title">
            {{ title }} <span></span>
            <div class="help-all">
              <div class="help-div">
                <ul class="help-ul">
                  <li class="margin-left-0">
                    <h4
                      style="
                        background: url(../../Uploads/2016-10-27/help_icon_7.png)
                          no-repeat 6px 6px;
                        padding-right: 20px;
                        border-bottom: 1px solid #dddddd;
                        color: #666666;
                        background-size: 30px 30px;
                      "
                    >
                      注册与购买
                    </h4>
                    <p
                      @click="changetitle(1, index)"
                      v-for="(item, index) in list"
                      :key="index"
                    >
                      <a>{{ item }}</a>
                    </p>
                  </li>
                  <li class="">
                    <h4
                      style="
                        no-repeat 6px 6px;
                        padding-right: 20px;
                        border-bottom: 1px solid #dddddd;
                        color: #666666;
                        background-size: 30px 30px;
                      "
                    >
                      产品问题
                    </h4>
                    <p
                      @click="changetitle(2, index)"
                      v-for="(item, index) in list2"
                      :key="index"
                    >
                      <a>{{ item }}</a>
                    </p>
                  </li>
                  <li class="">
                    <h4
                      style="
                        background: url(../../Uploads/2016-10-27/help_icon_5.png)
                          no-repeat 6px 6px;
                        padding-right: 20px;
                        border-bottom: 1px solid #dddddd;
                        color: #666666;
                        background-size: 30px 30px;
                      "
                    >
                      使用规则
                    </h4>
                    <p
                      @click="changetitle(3, index)"
                      v-for="(item, index) in list3"
                      :key="index"
                    >
                      <a>{{ item }}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </dt>
          <dl v-for="(item, index) in newlist" :key="index">
            <dt>
              <a
                @click="change(item, index)"
                style="padding-left: 30px; box-sizing: border-box"
                :class="{ active: isActive == index }"
                >{{ item }}</a
              >
            </dt>
          </dl>
        </div>

        <div class="common-questions sub1-common-questions">
          <div class="crumbs-nav clearfix">
            <p>
              <a @click="isshow" class="menu-lv1"></a>
              <a class="menu-lv2">&nbsp;{{ name }}&nbsp;</a>
            </p>
          </div>
          <div class="q-block clearfix">
            <ul class="q-list">
              <li>内容待补充</li>
            </ul>
            <div class="page-btn">
              <table cellspacing="0" cellpadding="0"></table>
            </div>
          </div>
        </div>
        <div class="clear-float"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "账户问题",
      isindex: true,
      isActive: 0,
      title: "",
      newlist: [],
      img1: require("@/assets/Uploads/2016-10-27/help_icon_7.png"),
      img2: require("@/assets/Uploads/2016-10-27/help_icon_4.png"),
      img3: require("@/assets/Uploads/2016-10-27/help_icon_5.png"),
      list: ["账户问题", "选购指南", "付款结算", "合同及发票"],
      list2: ["售前咨询", "产品购买与开通", "产品续费", "售后服务"],
      list3: ["常见问题", "规则说明", "总则", "信息安全处罚规则", "法律法规"],
    };
  },
  methods: {
    change(item, index) {
      this.name = item;
      this.isActive = index;
    },
    isshow(who) {
      if (who == 1) {
        this.title = "注册与购买";
        this.newlist = this.list;
      }
      if (who == 2) {
        this.title = "产品问题";
        this.newlist = this.list2;
      }
      if (who == 3) {
        this.title = "使用规则";
        this.newlist = this.list3;
      }
      this.isindex = !this.isindex;
    },
    changetitle(num, index) {
      this.isActive = index;
      if (num == 1) {
        this.title = "注册与购买";
        this.newlist = this.list;
      }
      if (num == 2) {
        this.title = "产品问题";
        this.newlist = this.list2;
      }
      if (num == 3) {
        this.title = "使用规则";
        this.newlist = this.list3;
      }
    },
  },
};
</script>

<style scoped>
.active {
  border-left: 5px solid #0088ff;
  color: #0088ff;
}
.help-menu dt {
  padding: 0px;
}
</style>
